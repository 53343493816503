













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Office } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../../../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class MoretonBayRegionTenancyApplicationForm extends Mixins(View) {
  private formCtx: Context | null = null

  private office: Office | null = null

  mounted() {
    const officeId = 'c2b66df1-c1b8-44b8-baf6-df2607bb96cf'; // Moreton Bay Region

    API.Offices.Get(officeId).then((office) => {
      this.office = office;
    });

    const ctx: Context = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.Office,
          Id: officeId,
        },
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: '906d49e3-32bd-4a8e-ae9f-e117ee88f60b', // Morayfield Rentals
        },
      ],
    };

    const listingId = this.$route.query.listingId ?? undefined;

    if (listingId) {
      ctx.Items.push({
        Type: ContextItemType.Listing,
        Id: listingId as string,
      });
    }

    this.formCtx = ctx;
  }
}
